<template>
  <div class="container add-global-modal-main-container">
    <h1
      class="add-global-modal-main-title"
      v-if="!commercialBusinessInformation"
    >
      Agregar Giro Comercial
    </h1>

    <div
      class="global-form-hint-container"
      v-if="!commercialBusinessInformation"
    >
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Clave del giro (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newCommercialBusinessRequiredErrors.GIRO_EMP }"
        :message="{
          'La clave del giro no es valida':
            newCommercialBusinessRequiredErrors.GIRO_EMP,
        }"
      >
        <b-input
          :disabled="commercialBusinessInformation"
          placeholder="Ejemplo: 15"
          expanded
          icon="numeric"
          v-model="newCommercialBusiness.GIRO_EMP"
          required
        ></b-input>
      </b-field>
      <b-field
        label="Descripción del giro (Requerido)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Descripción del giro"
          expanded
          icon="clipboard"
          v-model="newCommercialBusiness.DESCRIPCION_GIR"
          required
        ></b-input>
      </b-field>
    </b-field>

    <div class="add-global-controls" v-if="commercialBusinessInformation">
      <b-button type="is-success" @click="saveCommercialBusiness(1)">
        Actualizar giro comercial
      </b-button>
    </div>

    <div class="add-global-controls" v-if="!commercialBusinessInformation">
      <b-button type="is-success" @click="saveCommercialBusiness(0)"
        >Añadir giro comercial</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
import { fillObjectForm } from "../../../utils/component-fns";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";
// @ is an alias to /src
export default {
  name: "AddCommercialBusiness",
  props: ["type", "commercialBusinessInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      newCommercialBusiness: {
        STATUS: "AC",
        TIPO: this.type === 0 ? "C" : "P",
        GIRO_EMP: "",
        DESCRIPCION_GIR: "",
      },
      newCommercialBusinessRequiredErrors: {
        GIRO_EMP: false,
      },
    };
  },
  mounted() {
    // Fill the prices list information in form fields
    if (this.commercialBusinessInformation) {
      // Fill the form with received information
      fillObjectForm(
        this.commercialBusinessInformation,
        this.newCommercialBusiness
      );
      this.newCommercialBusiness._id = this.commercialBusinessInformation._id;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves a new brand in the database, display messages according to the status
     */
    async saveCommercialBusiness(type) {
      try {
        this.newCommercialBusinessRequiredErrors = validateFields(
          this.newCommercialBusiness,
          this.newCommercialBusinessRequiredErrors
        );
        if (checkValidationErrors(this.newCommercialBusinessRequiredErrors)) {
          let response = await this.$store.dispatch(
            type === 0 ? "SAVECOMMERCIALBUSINESS" : "EDITCOMMERCIALBUSINESS",
            this.newCommercialBusiness
          );
          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente el giro comercial",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                if (type === 0) this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar el giro comercial en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el giro comercial en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar el giro comercial en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    deleteInformation() {
      this.newCommercialBusiness = {
        TIPO: "AC",
        STATUS: this.type === 0 ? "C" : "P",
        GIRO_EMP: "",
        DESCRIPCION_GIR: "",
      };
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
